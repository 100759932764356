import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Col, Row, Button } from 'react-bootstrap';
//import UpcomingEvents from './UpcomingEvents'
//import TheologicalInsights from '../TheologicalInsights';
import LatestCalendar from '../upcoming/LatestCalendar';
import { Link } from 'react-router-dom';
import AboutUsHomepageView from '../../about/AboutUsHomepageView';
import './Upcoming.css';
import vjgroup from '../../../assets/images/groupphoto24.png';
import princimsg from '../../../assets/images/princimsg.jpg';
import princi from '../../../assets/photos/RajKumarFrSJ.jpeg';
import imgvjgp from '../../../assets/photos/rajkumarsj.jpg'

const config = require('../../../config.json');

export default function UpcomingMain() {
  const [insights, setInsights] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(6);



  useEffect(() => {
    fetchActiveInfo();
  }, []);

  const generateDate = async () => {
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString();
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    var todayDate="";
    const tDate = todayDate.concat(date_year,"-",month_value,"-", date_value);
    //setUserId(accountNo);
    return tDate;
    //console.log("acc : ", accountNo);
} 


const fetchActiveInfo =async()=>{
    try{
       setLoaded(false);
        const startDate=await generateDate();
        const clientID="VJCollege";
        const searchOption="UpComing Events";
    await axios.get(`${config.api.invokeURL}/dashboard/infodesk?clientID=${clientID}&startDate=${startDate}&searchOption=${searchOption}`,{
        headers: {
         // Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        if(response.data.length>0){
            const test=response.data;
            //const result=test.sort((a,b) => (a.updatedOn > b.updatedOn) ? 1 : ((b.updatedOn > a.updatedOn) ? -1 : 0))
            const result=test.sort((a,b) => new Date(a.startDate) - new Date(b.startDate) ); // b - a for reverse sort
            //console.log(result);
            setInsights(result);
            setShow(4);
            setLoaded(true);
        }else{
          setShow(6);
          setLoaded(true);
        }
    }).catch((error)=>{
        setLoaded(true);
    })
}catch(error){
    setLoaded(true);
}
}

  return (
    <div className='caroselrow mr-2' style={{paddingTop:"4%"}}>
        <Row>
            {insights.length>0 &&<Col md={show} >
            <LatestCalendar />
          {/*}  <UpcomingEvents /> {/*style={{height:"580px", overflow:"scroll"}}*/}
          
            </Col>}
           {/*} <Col md={show}>
            
                <div  className="row  text-center" style={{color:"#79031d", paddingBottom:"10px"}}>
        <h3>About VJ</h3>
    </div>
               <div>
               <div  style={{textAlign:"center", justifyContent:"center"}}>
               <div className='card' style={{padding:"10px"}}>
                <div style={{borderRadius:"35px", maxWidth:"100%", height:"250px"}}>
            <img className='rounded img_style img-fluid'  src={vjgroup} alt='' height={"150%"} width={"100%"}/>
            </div>
            <div>
              <p style={{textAlign:"justify", paddingTop:"30px"}} className='truncate-3-lines'>
              Vidyajyoti has a long history of about 150+ years. It was founded as a ‘Seminary’ in 1879 in Asansol, and was transferred in 1889 
              to Kurseong, Darjeeling District, where it took the name of St. Mary’s College. With the French Jesuits of Madurai Mission opening Sacred Heart College at Shembaganur in 1898 for the students of philosophy, Kurseong became exclusively a Jesuit Theologate. In the year 1900 there were 29 scholastics, of whom 11 were Indians, mostly from Calicut and Madurai Missions.</p>
              <Link to="/about/history" className='btn btn-primary'>Read More</Link>
              </div>
              </div>
            </div>
                </div> 

            </Col>*/}
            <Col md={show}>
            {/*<TheologicalInsights />*/}
            <div >
            <div>
              {/*
            <div  className="row" style={{color:"#79031d", paddingBottom:"10px"}}>
        <h3>About VJ</h3>
    </div>
    <div className='card' style={{padding:"10px"}}>
    <img  className='rounded img-style'  style={{borderRadius:"35px", maxWidth:"100%"}} src={vjgroup} alt='' height="350px" width="900px"/>
           
      <p style={{textAlign:"justify", paddingTop:"30px"}} className='truncate-3-lines'> 
      Vidyajyoti has a long history of about 150+ years. It was founded as a ‘Seminary’ in 1879 in Asansol, and was transferred in 1889 
      to Kurseong, Darjeeling District, where it took the name of St. Mary’s College. With the French Jesuits of Madurai Mission opening Sacred Heart College at Shembaganur in 1898 for the students of philosophy, Kurseong became exclusively a Jesuit Theologate. In the year 1900 there were 29 scholastics, of whom 11 were Indians, mostly from Calicut and Madurai Missions.              </p>
      <Button className='text-white'  variant="primary" type="submit" ><Link style={{textDecoration:"none"}} to="/about/principalmessage" className='text-white'>Read More</Link></Button>
      </div>
      */}
        <div  className="row" style={{color:"#79031d", paddingBottom:"10px"}}>
        <h3>About VJ</h3>
    </div>
    
    <div  style={{padding:"10px"}}>
    <img  className='rounded img-fluid'  style={{borderRadius:"35px", maxWidth:"100%"}} src={vjgroup} alt='' height="250px" width="700px"/>
           
      <p style={{textAlign:"justify", paddingTop:"30px"}} className='truncate-6-lines'> 
      Vidyajyoti has a long history of about 150+ years. It was founded as a ‘Seminary’ in 1879 in Asansol, and was transferred in 1889 
      to Kurseong, Darjeeling District, where it took the name of St. Mary’s College. With the French Jesuits of Madurai Mission opening Sacred Heart College at Shembaganur in 1898 for the students of philosophy, Kurseong became exclusively a Jesuit Theologate. In the year 1900 there were 29 scholastics, of whom 11 were Indians, mostly from Calicut and Madurai Missions.              </p>
     

     <div >
      <Button  className='button-style text-white'  variant="primary" type="submit" ><Link style={{textDecoration:"none"}} to="/about/history" className='text-white'>Read More   <i className="fa fa-arrow-right" style={{backgroundColor:"white", color:"blue", borderRadius:"50%", padding:"5px"}} aria-hidden="true"></i></Link></Button>
      
      </div> 
      </div>
      </div>
      </div>
            </Col>
            <Col md={show}>
            {/*<TheologicalInsights />*/}
            <div style={{marginRight:"10px"}} >
            <div>
          {/*  <div  className="row" style={{color:"#79031d", paddingBottom:"10px"}}>
              
        <h3>Principal's Desk</h3>
    </div>
    <div className='card' style={{padding:"10px"}}>
    <img  className='rounded img-style'  style={{borderRadius:"35px", maxWidth:"100%"}} src={imgvjgp} alt='' height="350px" width="900px"/>
           
      <p style={{textAlign:"justify", paddingTop:"30px"}} className='truncate-3-lines'> 
              Vidyajyoti is not just an institution. It is an ATTITUDE of theologizing in the Context. It shapes the way of life. 
              Vidyajyoti is a name of its own significance. Vidyajyoti means Light of Knowledge and the motto of VJ is taken from the Katha Upanishad, 5:15, “tam eva bhantam anubuti sarvam” meaning “Everything reflects the one who alone shines”. Guided by that spirit Vidyajyoti engages very seriously in Contextual Theology and thereby striving to make Life-realities, struggles and sufferings of people our starting point for Theologizing.
              </p>
      <Button className='text-white'  variant="primary" type="submit" ><Link style={{textDecoration:"none"}} to="/about/principalmessage" className='text-white'>Read More</Link></Button>
      </div>
      */}
      <div>
        {/*
      <div  className="row" style={{color:"#79031d"}}>
              
              <h3 style={{paddingBottom:"5%"}}>Principal's Desk</h3>
          </div>
        <Row>
          <Col lg={6} style={{textAlign:"left"}}>
         
          <div className='princi-text'>
             
          <p style={{textAlign:"justify"}} className='truncate-6-lines p-1'> 
              Vidyajyoti is not just an institution. It is an ATTITUDE of theologizing in the Context. It shapes the way of life. 
              Vidyajyoti is a name of its own significance. Vidyajyoti means Light of Knowledge... 
             
              </p>
          </div>
        
          </Col>
          <Col lg={6} style={{zIndex:"-9"}}>
          <div className='principal-img-bg'>
              <img  className='img-fluid'  style={{borderRadius:"50%", border:"5px solid #009ced"}} src={imgvjgp} alt='' height="350px" width="350px"/>
   
          </div>
          <h5 className='principal-title '>
          <b>  Prof. Dr. Rajkumar Joseph SJ </b>
          </h5>
          <div className='principal-text'>
              <p>Principal <br/> Vidyajyothi College of Theology</p>
            </div>
        
          </Col>
          
          <div className='princi-button'>
            <Button className='button-style text-white'  variant="primary" type="submit" ><Link style={{textDecoration:"none"}} to="/about/principalmessage" className='text-white'>Read More  <i className="fa fa-arrow-right" style={{backgroundColor:"white", color:"blue", borderRadius:"50%", padding:"5px"}} aria-hidden="true"></i></Link></Button>
     
          </div>
          
        </Row>
        */}
       {/*}  <div  className="row" style={{color:"#79031d"}}>
              
              <h3 style={{paddingBottom:"5%"}}>Principal's Desk</h3>
          </div>
          <Row>
          <Col  style={{textAlign:"left"}}>
         
          <div className='princi-text'>
             
          <p style={{textAlign:"justify"}} className='truncate-6-lines p-1'> 
              Vidyajyoti is not just an institution. It is an ATTITUDE of theologizing in the Context. It shapes the way of life. 
              Vidyajyoti is a name of its own significance. Vidyajyoti means Light of Knowledge... 
             
              </p>
          </div>
        
          
          <div className='principal-img-bg'>
              <img  className='img-fluid'  style={{borderRadius:"50%", border:"5px solid #009ced"}} src={imgvjgp} alt='' height="150px" width="150px"/>
   
          </div>
         
          <div style={{marginTop:"-32%"}}>
               <p style={{color:"black", lineHeight:"35px", textAlign:"right" , padding:"1%"}}><h4 style={{color:"#79031d", fontFamily:"Anton"}}>Prof. Dr. Fr. Rajakumar Joseph SJ</h4>
Vidyajyoti College of Theology,<br/>
Institute for Religious Studies,<br/>
Delhi – 54. 
</p>
          </div>
        
          </Col>
          
          <div className='princi-button'>
            <Button className='button-style text-white'  variant="primary" type="submit" ><Link style={{textDecoration:"none"}} to="/about/principalmessage" className='text-white'>Read More  <i className="fa fa-arrow-right" style={{backgroundColor:"white", color:"blue", borderRadius:"50%", padding:"5px"}} aria-hidden="true"></i></Link></Button>
     
          </div>
          
        </Row> */}

    <div  className="row" style={{color:"#79031d", paddingBottom:"10px"}}>
        <h3>Principal Message</h3>
    </div>
      <div className='princi-text'>
            <p style={{textAlign:"justify"}} className='truncate-8-lines'> 
             Vidyajyoti is not just an institution. It is an ATTITUDE of theologizing in the Context. It shapes the way of life. 
             Vidyajyoti is a name of its own significance. Vidyajyoti means Light of Knowledge and the motto of VJ is “Everything reflects the one who alone shines”. 
             Guided by that spirit Vidyajyoti engages very seriously in Contextual Theology and thereby striving to make Life-realities, struggles and sufferings of people our starting point for Theologizing.
            </p>
      </div>
    <div  >
    <img  className='rounded img-fluid'  style={{borderRadius:"35px", maxWidth:"100%"}} src={princimsg} alt='' height="500px" width="900px"/>
           
      </div>
      <div className='princi-button pt-2'>
            <Button className='button-style text-white'  variant="primary" type="submit" ><Link style={{textDecoration:"none"}} to="/about/principalmessage" className='text-white'>Read More  <i className="fa fa-arrow-right" style={{backgroundColor:"white", color:"blue", borderRadius:"50%", padding:"5px"}} aria-hidden="true"></i></Link></Button>
     
          </div>
      </div>
      </div>
      </div>
            </Col>
        </Row>
    </div>
  )
}
