import React, { useState, useEffect } from 'react';
//import LibraryHeader from './LibraryHeader';
import { latestArrivalList } from '../../ProData';
//import NewFaceView from '../NewFaceView';
import {Helmet} from "react-helmet-async";
import axios from 'axios';
import bookImage from '../../assets/images/cat1.png';
import defaultAvatar from '../../assets/photos/defaultAvatar.jpg';
import Preloader from '../../components/Preloader/Preloader';
const config = require('../../config.json');


export default function LatestArrivalsView() {
    const [insights, setInsights] = useState([]);
    const [loaded, setLoaded] = useState(false);
    //const [imgsrc, setImgsrc] = useState();

    useEffect(() => {
        fetchActiveInfo();
    }, [])

    const generateDate = async () => {
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        var todayDate="";
        const tDate = todayDate.concat(date_year,"-",month_value,"-", date_value);
        //setUserId(accountNo);
        return tDate;
        //console.log("acc : ", accountNo);
    } 

    const fetchActiveInfo =async()=>{
        try{
           setLoaded(false);
            const startDate=await generateDate();
            const clientID="VJCollege";
            const searchOption="Latest Arrivals";
        await axios.get(`${config.libraryapi.invokeURL}/vjlibrary/latestarrivals?clientID=${clientID}&startDate=${startDate}&searchOption=${searchOption}`,{
            headers: {
             // Authorization: access_token,
              'x-api-key': config.libraryapi.key
            }}, 
        ).then((response)=>{
            //console.log("res : ", response.data);
            if(response.data.length>0){
                const test=response.data;
                //const result=test.sort((a,b) => (a.updatedOn > b.updatedOn) ? 1 : ((b.updatedOn > a.updatedOn) ? -1 : 0))
                const result=test.sort((a,b) => new Date(b.startDate) - new Date(a.startDate) ); // b - a for reverse sort
                //console.log("Result :", result);
                setInsights(result);
                setLoaded(true);
            }
        }).catch((error)=>{
            setLoaded(true);
        })
    }catch(error){
        setLoaded(true);
    }
    }

    const handleImageError = (event) => {
        event.target.src = bookImage;
      };
  

  return (
    <>
    <Helmet>
      <title>Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/library/latest" />
    </Helmet>
    <Preloader show={loaded ? false : true} />

       {/*} <LibraryHeader />
        <NewFaceView />*/}
        <div className="container-fluid blog py-5 mb-5">
            <div className="container">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="text-primary">Our Library</h5>
                    <h1>Latest Arrivals</h1>
                </div>
                <div className="row g-5 justify-content-center">
                {insights.length>0 && insights.map((item, index)=>
                    <div key={index} className="col-sm-6 col-md-4 col-lg-3 col-xl-3 wow fadeIn " data-wow-delay=".3s">
                        <div className="blog-item position-relative bg-light rounded d-flex flex-column h-100">
                            <img src={`${config.s3.bookPoster}${item.accessionNo}${".jpeg"}`} className=" rounded-top zoom"  style={{ width:"100%", height:"20rem" }}  alt=""  onError={handleImageError}/>
                            {/*<span className="position-absolute px-4 py-3  bg-dark-main text-white rounded" style={{top: "-28px", right: "0px"}}>{item.bookPublishYear}</span>*/}
                            <div className="blog-content text-center position-relative h-100" >
                               {/*} <img src={defaultAvatar} className="img-fluid rounded-circle border border-4 border-white mb-3" alt="" style={{height:"90px", width:"90px"}} />*/}

                                <h6 className="">{item.bookTitle}</h6>
                            </div>
                            <div className=" text-center px-4 border bg-dark-main rounded-bottom">
                            <small className="text-dark-main"> {item.bookAuthor}</small>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </>
    )
}
