import React, { useState, useEffect } from 'react';
import Preloader from '../../components/Preloader/Preloader';
import {Helmet} from "react-helmet-async";

const playList=[{
  id:1,
  url:"https://www.youtube.com/embed/tbB_ZFyk3Ns",
  title:"VJ2022  Farewell 19/03/2022",
},{
    id:2,
    url:"https://www.youtube.com/embed/fZX18rE327A",
    title:"Inaugural Mass Vidya Jyoti College - New Delhi 10.08.2020",
},{
  id:3,
  url:"https://www.youtube.com/embed/BmTR_zL_tZo",
  title:"Karwani Muhabat 2017",
},{
  id:4,
  url:"https://www.youtube.com/embed/5CTMn2W7Dkg",
  title:"Ministry Presentation 2016",
},{
  id:5,
  url:"https://www.youtube.com/embed/4hLz9Y67NU8",
  title:"Asha  2016",
},{
  id:6,
  url:"https://www.youtube.com/embed/im9gcaHZqI4",
  title:"Balmela - 2015",
},{
  id:7,
  url:"https://www.youtube.com/embed/zZ2Wd9gd-Ug",
  title:"Diaconate 2016",
},{
  id:8,
  url:"https://www.youtube.com/embed/44TOjoE9vfg",
  title:"Theological Drama",
},{
  id:9,
  url:"https://www.youtube.com/embed/39iuJK34MyI",
  title:"Together along the way",
},{
  id:10,
  url:"https://www.youtube.com/embed/Ik7IG30en_s",
  title:"Theological Drama",
},{
  id:11,
  url:"https://www.youtube.com/embed/5OkYkS8sIQc",
  title:"Diaconate Ordination - 2017 || Vidyajyoti College, Delhi",
},{
  id:12,
  url:"https://www.youtube.com/embed/mOr-1cOwhb4",
  title:"Farewell 2014-2015 Batch - Vidyajyoti College of Theology, Delhi",
},{
  id:13,
  url:"https://www.youtube.com/embed/RUtFV04NW7Y",
  title:"Mission Possible Encountering God",
},{
  id:14,
  url:"https://www.youtube.com/embed/iiBuGvnopBs",
  title:"Vidya Jyoti College of Theology - Farewell of 2016-17 Batch",
},{
  id:15,
  url:"https://www.youtube.com/embed/_OiqqBlnKVg",
  title:"Diaconate Ordination 2020 || Vidyajyoti College of Theology, Delhi",
},{
  id:16,
  url:"https://www.youtube.com/embed/jgZg3NvmvAI",
  title:"Stand with Stan || Vidyajyoti College of Theology",
},{
  id:17,
  url:"https://www.youtube.com/embed/9pYPh22hgQk",
  title:"Diaconate Ordination on 26th February 2022  3 pm @ Millennium Hall, St Xavier&#39;s School New Delhi",
},{
  id:18,
  url:"https://www.youtube.com/embed/J9ji0LAyxOk",
  title:"Balmela 2013",
}];

export default function YouTubeChannel() {
  const [videoDetails, setVideoDetails]=useState({title:'', url:''});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setVideoDetails({...videoDetails,
      url:playList[0].url,
      title:playList[0].title});
    setLoaded(true);
  }, [])
  

  const handleVideoView=(event, item)=>{
    //event.preventDefault();
    try{
     // console.log("item :", item);
      setVideoDetails({...videoDetails,
      title:item.title,
      url:item.url
      });
    }catch(error){
      console.log("error");
    }
  }

  return (
    <>
    <Helmet>
      <title>Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/publications" />
    </Helmet>

    <Preloader show={loaded ? false : true} />
    <div className='container pb-5'>
        <h1 className="mb-3" style={{textAlign:"center"}}>Vidyajyoti YouTube Channel</h1>
        <p>CHANNEL LINK - <a href="https://www.youtube.com/channel/UCdNmmekVJMKCbO2lISZNCWw" target="_blank">https://www.youtube.com/channel/UCdNmmekVJMKCbO2lISZNCWw</a></p>
        <div id="videoView" className="container py-5" style={{textAlign:"center"}}>
          <iframe width="848" height="476" src={videoDetails.url} title={videoDetails.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div className="row g-5">
        {playList.map((item, index)=>
        <div key={index} className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
        <div className="project-item">
            <div className="project-img">
                <iframe className="img-fluid w-100 rounded" style={{height:"250px"}} src={item.url} title={item.title} frameborder="0" allow=" picture-in-picture;" ></iframe>
                <div className="project-content">
                    <a href="#videoView" className="text-center" onClick={event=>{handleVideoView(event, item)}}>
                        <h4 className="text-secondary"><i className="bi bi-youtube fa-7x mb-4"></i></h4>
                    </a>
                </div>
            </div>
        </div>
    </div>  
        )}
        </div>
    </div>
    </>
  )
}
