import React from 'react';
import {Helmet} from "react-helmet-async";

export default function Accomodation() {
  return (
    <>
     <Helmet>
      <title>Accomodation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Accomodation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Accomodation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/services/accomodation" />
    </Helmet>
    <div className='container pb-5' style={{padding:"10"}}>
        <h1 className="mb-3" style={{textAlign:"center", padding:"10px"}}>Accomodation</h1>
        <div style={{color:"black", textAlign:"justify", lineHeight:"28px"}}>
                <p>
                <h5 className="text-primary">Accomodation for Jesuits:</h5>
                <ul clasNames="checklist " style={{listStyle:"none"}}>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Vidyajyoti Jesuit residense which provides accommodation for the Jesuit scholastics who are doing theology at Vidyajyoti College of Theology. </li>
                    <li><i className="Ylight-text-combo bi bi-check-circle-fill p-2"></i>For further details contact: Rector, Vidyajyoti.</li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Email: rectorvj@gmail.com</li>
                </ul>
                </p>
            </div>
        <div style={{color:"black", textAlign:"justify", lineHeight:"28px"}}>
                <p>
                <h5 className="text-primary">Accomodation for the Women Students:</h5>
                <ul clasNames="checklist " style={{listStyle:"none"}}>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Vidyajyoti accommodates the women students who are doing theology at Vidyajyoti College of Theology in the nearby St. Stephen's hostel for women. </li>
                    <li><i className="Ylight-text-combo bi bi-check-circle-fill p-2"></i>For further details contact: Rector & Principal, Vidyajyoti.</li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Email: rectorvj@gmail.com & principalvj@gmail.com</li>
                </ul>
                </p>
            </div>
            <div style={{color:"black", textAlign:"justify", lineHeight:"28px"}}>
                <p>
                <h5 className="text-primary">Accomodation for the SEMINARIANS/RELIGIOUS:</h5>
                <ul clasNames="checklist " style={{listStyle:"none"}}>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Rector:	Fr. Jose T.J</li>
                    <li><i className="Ylight-text-combo bi bi-check-circle-fill p-2"></i>Address:	Pratiksha 8, Vishwa Apartments New Delhi - 110054</li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Phone: 9871811880</li>
                    <li><i className="Ylight-text-combo bi bi-check-circle-fill p-2"></i>E-mail: jobytj@gmail.com</li>
                </ul>
                </p>
            </div>
            <div style={{color:"black", textAlign:"justify", lineHeight:"28px"}}>
                <p>
                <h5 className="text-primary">Accomodation for the INDIAN MISSIONARY SOCIETY STUDENTS:</h5>
                <ul clasNames="checklist " style={{listStyle:"none"}}>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Rector:	Fr. Mahendra Paul, IMS</li>
                    <li><i className="Ylight-text-combo bi bi-check-circle-fill p-2"></i>Address:	Jnana Bharati Gurul A-1319, Lok Vihar, PitamPura New Delhi - 110034</li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Phone:	9452709755</li>
                    <li><i className="Ylight-text-combo bi bi-check-circle-fill p-2"></i>E-mail:	mahendrapaulims@gmail.com</li>
                </ul>
                </p>
            </div>
            <div style={{color:"black", textAlign:"justify", lineHeight:"28px"}}>
                <p>
                <h5 className="text-primary">Accomodation for the FARIDABAD SYROMALABAR SEMINARIANS:</h5>
                <ul clasNames="checklist " style={{listStyle:"none"}}>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Rector:	Fr. Joseph Tharayil (Frijo)</li>
                    <li><i className="Ylight-text-combo bi bi-check-circle-fill p-2"></i>Address:	Vidyajyoti Niwas, Modal Town, near Naini Lake, New Delhi - 110009</li>
                    <li><i className="text-dark-main bi bi-check-circle-fill p-2"></i>Phone: 6235240204</li>
                    <li><i className="Ylight-text-combo bi bi-check-circle-fill p-2"></i>E-mail: rectorvj@gmail.com</li>
                </ul>
                </p>
            </div>
    </div>
    </>
  
  )
}
