import React, {useState, useEffect} from 'react';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import axios from "axios";
import { useSnackbar } from 'notistack';
import {Helmet} from "react-helmet-async";
import onlineadmissionimg from '../../assets/images/online admission.jpg'
import LanguageForm from './LanguageForm';
import InstitutionForm from './InstitutionForm';
import DocSuperiorLetter from './DocSuperiorLetter';
import DocMarkList from './DocMarkList';
import DocPhotoProof from './DocPhotoProof';
import DocBPHCert from './DocBPHCert';
import { phoneCode , coursesOffered} from '../../ProData';
import CustomSpinner from '../../components/customerSpinner/CustomSpinner';
//import { event } from 'jquery';
import PreviewScreen from './PreviewSceen';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function OnlineApplication() {
    const [profileDetails, setProfileDetails]=useState({applicantName:'', fatherName:'', dateOfBirth:'', gender:'Male', nationality:'',
    religiousStatus:'Scholastic', parishDiocese:'', congregationProvince:'', areaCode:'+91',mobileNumber:'', emailID:'', placeOfBirth:'', 
    birthDistrict:'', birthState:'', localAddress:'', localState:'', localPincode:'', localContact:'', perAddress:'', perState:'', perPincode:'',
    perContactNo:'', courseCategory:'Bachelor of Theology', degreeDetails:[], languageDetails:[], courseDetails:[]});
    const [imageFile, setImageFile] = useState('');
    const [certProofFile, setCertProofFile]=useState('');
    const [markListFile, setMarkListFile]=useState('');
    const [recommenLetterFile, setRecommenLetterFile]=useState('');
    const [loaded, setLoaded] = useState(true);
    const [app, setApp]=useState({appNo:'', appMsg:''});
    const [applicationProcessed, setApplicationProcessed]=useState(false);
    const [selectedCourse, setSelectedCourse]=useState({});
    const [isCourseShortlisted, setIsCoursseShortlisted]= useState(false);
    const [viewOnly, setViewOnly] = useState(true);
    const [optedCourse, setOptedCourse]=useState({courseName:'',courseCategory:''});
    const [emailCodeReceived, setEmailCodeReceived] = useState('');
    const [emailCodeEntered, setEmailCodeEntered]= useState('')
    const [isEmailValidated, setIsEmailValidated]=useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [coursesAvailable, setCoursesAvailable]= useState([]);
    const [courseOptionAvailable, setCourseOptionAvailable]= useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [isPreviewScreen, setIsPreviewScreen] = useState(false);

    useEffect(()=>{
          setViewOnly(false);
          initializeValues();
      },[])

      const handlePreviewScreen =async(event)=>{
        event.preventDefault();
       // const validationResult=validateFields();
      try{
        const validationResult=validateFields();
       //const validationResult="passed";
        if(validationResult==="passed"){
        setLoaded(false);
            await axios.get(`${config.api.invokeURL}/form/registration/emailvalidation?emailID=${profileDetails.emailID}`,{
                headers: {
                 // Authorization: access_token,
                  'x-api-key': config.api.key
                }}, 
            ).then((response)=>{
              if(response.status===200){
                setEmailCodeReceived(response.data);
                setViewOnly(true);
                setIsPreviewScreen(true);      
                setIsEmailValidated(true);
                enqueueSnackbar("One Time password has been sent to your email addresss",{variant:'success'}); 
              }else{
                  enqueueSnackbar("Duplicate Records Found",{variant:'warning'}); 
              }
                setLoaded(true);
            }).catch((error)=>{
                //console.log(error);
                enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                setLoaded(true);
            })
          }
      }catch(error){
        setLoaded(true);
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});       
      }
    }
    
    const initializeValues=()=>{
        var uniqueArray = removeDuplicates("courseName");
        var sortedcourseArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
        setCoursesAvailable(sortedcourseArray);
        //console.log("Courses Name : ", sortedArray);
        uniqueArray = removeDuplicates("courseOption");
        var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
        setCourseOptionAvailable(sortedOptionArray);
        setOptedCourse({...optedCourse,
            courseName:sortedcourseArray[0].courseName,
            courseOption:sortedOptionArray[0].courseOption
        });
    }

    const removeDuplicates=(tprop)=> {
        var newArray = [];
        var lookupObject  = {};
        for(var i in coursesOffered) {
            lookupObject[coursesOffered[i][tprop]] = coursesOffered[i];
        }
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    const generateDate=()=>{
        const current_datetime = new Date();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        const accountNo = tempUserId.concat(date_year,"-",month_value,"-", date_value);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;
        //console.log("acc : ", accountNo); 
    }

    const generateApplicationNo=()=>{
        const current_datetime = new Date();
        const fName=profileDetails.applicantName.trim().replace(/\s/g, "");
        //const tusername=memberInfo.name.trim().replace(/\s/g, "");
        const tfName= fName.substring(0,2).toUpperCase();
        const lName=profileDetails.fatherName.trim().replace(/\s/g, "");
        //const tusername=memberInfo.name.trim().replace(/\s/g, "");
        const tlName= lName.substring(0,2).toUpperCase();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value,tfName, tlName);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;
        //console.log("acc : ", accountNo);
    }

    const handleCourseSelection =async(event)=>{
        event.preventDefault();
        for(const i in coursesOffered){
        //  console.log("selected Course : ", coursesOffered[i] );
          if(coursesOffered[i].courseName===optedCourse.courseName && coursesOffered[i].courseOption===optedCourse.courseOption){
          //  console.log("selected Course : ", coursesOffered[i] );
          //console.log("Opted Course : ", optedCourse);
            setSelectedCourse(coursesOffered[i]);
            await setProfileDetails({...profileDetails, 
                courseCategory:optedCourse.courseName, 
                courseDetails:coursesOffered[i]});
            setIsEmailValidated(false);
            setIsFormSubmitted(false);        
            setIsCoursseShortlisted(true);
            window.scrollTo(0,0);
          }
        }
      }

    const handleEmailValidation=async(event)=>{
        event.preventDefault();
        //console.log("testing");
        try{
          const validationResult=validateFields();
          if(validationResult==="passed"){
          setLoaded(false);
              //const clientID="VJDEPTH";
              await axios.get(`${config.api.invokeURL}/form/registration/emailvalidation?emailID=${profileDetails.emailID}`,{
                  headers: {
                   // Authorization: access_token,
                    'x-api-key': config.api.key
                  }}, 
              ).then((response)=>{
                if(response.status===200){
                  setEmailCodeReceived(response.data);
                  setIsEmailValidated(true);
                  enqueueSnackbar("One Time password has been sent to your email addresss",{variant:'success'}); 
                }else{
                    enqueueSnackbar("Duplicate Records Found",{variant:'warning'}); 
                }
                  setLoaded(true);
              }).catch((error)=>{
                  //console.log(error);
                  enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                  setLoaded(true);
              })
            }
        }catch(error){
          setLoaded(true);
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});       
        }
      }

    const handleNewForm=()=>{
        resetValues();
        setApp({...app, appNo:'', appMsg:''});
        setApplicationProcessed(false);
        setIsEmailValidated(false);
        setIsFormSubmitted(false);
        setIsPreviewScreen(false);
      }

      const resetValues=()=>{
        setProfileDetails({...profileDetails,applicantName:'', fatherName:'', dateOfBirth:'', gender:'Male', nationality:'',
        religiousStatus:'Scholastic', parishDiocese:'', congregationProvince:'', areaCode:'+91',mobileNumber:'', emailID:'', placeOfBirth:'', 
        birthDistrict:'', birthState:'', localAddress:'', localState:'', localPincode:'', localContact:'', perAddress:'', perState:'', perPincode:'',
        perContactNo:'', courseCategory:'', degreeDetails:[], languageDetails:[], courseDetails:[]});
        setImageFile('');
        setMarkListFile('');
        setCertProofFile('');
        setRecommenLetterFile('');
        setIsCoursseShortlisted(false);
      }

      const validateFields=()=>{
        const {applicantName, fatherName, dateOfBirth, gender, birthDistrict, birthState, religiousStatus, parishDiocese, 
          congregationProvince, mobileNumber, emailID, placeOfBirth, localAddress, localState, 
          localPincode, nationality, localContact, perAddress, perState, perPincode, perContactNo, courseCategory,
          languageDetails, degreeDetails }=profileDetails;
          console.log("profile : ", profileDetails);
          var returnValue="passed";
          if(courseCategory===""){
            enqueueSnackbar("Select the course first!",{variant:'warning'});
            returnValue="failed";  
          }else if(!emailID.match(validRegex)){
              enqueueSnackbar("Email address is not in right format!",{variant:'warning'});
          returnValue="failed";
          }else  if(applicantName===""){
          enqueueSnackbar("Name cannot be empty!",{variant:'warning'}); 
        returnValue="failed";
      }else  if(dateOfBirth===""){
          enqueueSnackbar("Date of Birth cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(gender===""){
          enqueueSnackbar("Gender cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(religiousStatus===""){
          enqueueSnackbar("Religious Status cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(fatherName===""){
          enqueueSnackbar("Father Name cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(birthDistrict===""){
          enqueueSnackbar("District cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(mobileNumber===""){
          enqueueSnackbar("Mobile Number cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(emailID===""){
          enqueueSnackbar("Email Address cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(birthState===""){
          enqueueSnackbar("State cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(parishDiocese===""){
          enqueueSnackbar("Parish Diocese cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(nationality===""){
          enqueueSnackbar("Nationality cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(placeOfBirth===""){
          enqueueSnackbar("Place Of Birth cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(languageDetails===""){
          enqueueSnackbar("Language Details cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(localAddress===""||localState===""||localPincode===""||localContact===""){
          enqueueSnackbar("Local Address cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(perAddress===""||perState===""||perPincode===""||perContactNo===""){
          enqueueSnackbar("Permanent Address cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(degreeDetails===""){
          enqueueSnackbar("Degree Details cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(imageFile===""){
          enqueueSnackbar("Attach your Photo. Photo is must for registration!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(markListFile===""){
          enqueueSnackbar("Attach Mark List Copy. It's mandatory for registration!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(certProofFile===""){
          enqueueSnackbar("Attach UG/PG certificate. It's mandatory for registration.",{variant:'warning'}); 
      returnValue="failed";
      }/*else  if(recommenLetterFile===""){
          enqueueSnackbar("Attach Recommendation Letter. It's mandatory for registration.",{variant:'warning'}); 
      returnValue="failed";
      }*/else  {
        returnValue="passed";
      }
      return returnValue;
      }

      const postOnS3 = async(applicationno,timageFile,inputType)=>{
        try{
            var tKeyName="";
            var newFileName="";
            var tempMsg="";
            if(inputType==="Profile"){
              tKeyName="/avatar/"+applicationno;   
              newFileName=applicationno+".jpeg";
              tempMsg="Profile picture Uploaded!";
            }else if (inputType==="Recomm"){
              tKeyName="/avatar/"+applicationno+"/docs";  
              newFileName=applicationno+"_recommLetter.jpeg"; 
              tempMsg="Recommendation Letter Uploaded!";
            }else if (inputType==="CERTProof"){
              tKeyName="/avatar/"+applicationno+"/docs";  
              newFileName=applicationno+"_certproof.jpeg";
              tempMsg="Certificate Uploaded!"; 
            }else if (inputType==="MarkList"){
              tKeyName="/avatar/"+applicationno+"/docs";  
              newFileName=applicationno+"_markproof.jpeg"; 
              tempMsg="Marksheet Uploaded!"; 
            }   
          
          var file = timageFile;
          var blob = file.slice(0, file.size, 'image/jpeg'); 
          const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
          const bucketName=config.s3.avatarbucketName + tKeyName;
          await axios.get(`${config.api.invokeURL}/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
               headers:{
                  // Authorization: access_token,
                   'x-api-key': config.api.key
               }
           }).then(async(response)=>{
             //  console.log("Response : ", response);
              const res=await fetch(response.data.uploadURL, { method: 'PUT',
               headers:{
                   "Content-Type":"image/jpeg"
                 },body: newFile});
                 if(res.status===200){
                    enqueueSnackbar(tempMsg,{variant:'success'}); 
                 }
            }).catch((error)=>{
               // console.log("error1:", error);
               enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
             // setDispMsg(error.message);
             // hideLoader();
           })
        }catch(error){
         // console.log("error1:", error);
         enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        }
      }

    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
          //console.log("value");
        const validationResult=validateFields();
        //const validationResult="passed";
        if(validationResult==="passed"){
          setLoaded(false);
          const {applicantName, fatherName, dateOfBirth, gender, birthDistrict, birthState, religiousStatus, parishDiocese, 
          congregationProvince, areaCode, mobileNumber, emailID, placeOfBirth, localAddress, localState, 
          localPincode, nationality, localContact, perAddress, perState, perPincode, perContactNo, courseCategory,
          languageDetails, degreeDetails, courseDetails }=profileDetails;
          const clientID = "VJCollege";
          const applicationno= await generateApplicationNo();
          const applicationDate=await generateDate();
          const applicationStatus="Pending Approval";
          const avatar= applicationno+".jpeg";
          const trecommLetter=applicationno+"_recommLetter.jpeg"; 
          const tcertproofFile=applicationno+"_certproof.jpeg";  
          const tmarkListFile=applicationno+"_markproof.jpeg";
          const tcourseStatus="Pending Approval";
          const tcoursePayment="Pending";   
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          const params={
            clientID:clientID, applicationNo: applicationno, applicationDate: applicationDate, applicationStatus:applicationStatus,
            applicantName:applicantName,fatherName:fatherName, birthDistrict:birthDistrict, birthState:birthState, dateOfBirth:dateOfBirth, gender:gender, 
            religiousStatus:religiousStatus, parishDiocese:parishDiocese, congregationProvince:congregationProvince, placeOfBirth:placeOfBirth, 
            localAddress:localAddress, areaCode:areaCode, mobileNumber:mobileNumber, emailID:emailID, localState:localState, localPincode:localPincode, 
            localContact:localContact, perAddress:perAddress, nationality:nationality, perState:perState, perPincode:perPincode, 
            selectedCourse:selectedCourse, perContactNo:perContactNo, avatar:avatar, recommLetter:trecommLetter, markListFile:tmarkListFile,
            certproofFile:tcertproofFile, courseStatus:tcourseStatus, coursePayment:tcoursePayment, courseCategory:courseCategory, 
            degreeDetails:degreeDetails, languageDetails:languageDetails, courseDetails:courseDetails};
          //console.log("Params : ", params);
          await axios.post(`${config.api.invokeURL}/dashboard/registration/${applicationno}`, params,{
            headers: {
             // Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
              //console.log("response : ", response); 
              await postOnS3(applicationno, imageFile,"Profile");
              await postOnS3(applicationno, recommenLetterFile,"Recomm");
              await postOnS3(applicationno, certProofFile,"CERTProof");
              await postOnS3(applicationno, markListFile,"MarkList");
              setApplicationProcessed(true);
              setIsFormSubmitted(true);
              setApp({...app, appNo:applicationno, appMsg:"Congratulations and Your Application Registration is successful. Your application will be reviewed throughly and further updates will be shared via Email. You can also get the latest updates from our portal."});
              enqueueSnackbar(applicationno + " - Successfully Registered.",{variant:'success'}); 
                resetValues();
                setLoaded(true);
            }).catch((error)=>{
           //   console.log("error : ", error);
              enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
              setLoaded(true);
            })
        }
          }catch(error){
           //console.log("error : ", error);
           enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
           setLoaded(true);
        }
      }  


  return (
    <div className='container' style={{color:"black"}}>
    <Helmet>
      <title>Application Form - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Application Form - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Application Form - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/admission/application-form" />
    </Helmet>

        {!loaded &&  <CustomSpinner />}
        
        {!isCourseShortlisted && !applicationProcessed?

       <Card border="light" className=" bg-white shadow-sm mb-4">
      
       <Card.Body>    
       <h1 className="mb-3" style={{textAlign:"center", padding:"10px"}}>APPLICATION FOR ADMISSION</h1>
       <Row style={{height:"450px"}}>
          <Col md={6}>
            <img src={onlineadmissionimg} alt="" className='img-fluid'/>
          </Col>
          <Col md={6} style={{margin:"auto",fontFamily:"Saira"}}>
          <h5 className="mb-4">Registration - Select the Course</h5>
         <Form >
         <Row>
         <Form.Group id="otp">
                 <Form.Label style={{fontSize:"18px",fontFamily:"Saira"}}>Select the Course</Form.Label>
                 <Form.Select type="select" name="courseName"
                    value={optedCourse.courseName} 
                    onChange={(event)=>{setOptedCourse({...optedCourse, courseName:(event.target.value)})}}
                    >
                    {coursesAvailable.map(item => {
                        return (<option key={item.courseName} value={item.courseName}>{item.courseName}</option>);
                    })}
                  </Form.Select>
               </Form.Group>
         </Row>
    
         <div className="mt-3 text-center">
             <Button className="btn bg-dark-main text-white" type="submit" onClick={(event)=>{handleCourseSelection(event)}}>Select Course</Button>
           </div>
         </Form>
          </Col>
          </Row> 
       
         </Card.Body>
      </Card>:null}

        {!isEmailValidated && isCourseShortlisted && !isPreviewScreen ?  
        <div className="p-2 rounded contact-form">
        <Card>
      <Card.Body>
        <Form style={{fontSize:"18px"}}>
        <Row>
           
          </Row> 
          <Row>
          <h1 className="mb-3" style={{textAlign:"center", padding:"10px"}}>APPLICATION FOR ADMISSION</h1>
            <Col md={6} className="mb-3">
            
              <Form.Group id="firstName">
                <Form.Label><h5>Student Name</h5></Form.Label>
                <Form.Control required type="text" placeholder="Name (As per the Certificate)" value={profileDetails.applicantName}
                                 disabled={viewOnly}
                                 onChange={(event)=>{setProfileDetails({...profileDetails, applicantName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label><h5>Father Name</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.fatherName}
                    disabled={viewOnly} placeholder='Father name...'
                    onChange={(event)=>{setProfileDetails({...profileDetails, fatherName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label><h5>Email</h5></Form.Label>
                <Form.Control required type="email" value={profileDetails.emailID}
                    disabled={viewOnly} placeholder='Email address...'
                    onChange={(event)=>{setProfileDetails({...profileDetails, emailID:event.target.value})}}
                  />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
            <Form.Label><h5>Mobile Number</h5></Form.Label>
            <Row>
              <Col sm={4} className="mb-3">
              <Form.Group id="areanumber">
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                type="select" name="courseName"
                      value={profileDetails.areaCode} 
                      disabled={viewOnly}
                      onChange={(event)=>{setProfileDetails({...profileDetails, areaCode:event.target.value})}}
                    >
                      {phoneCode.map(item => {
                    return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                  })}
                  </Form.Select>
                 </Form.Group>
            </Col>
             <Col sm={8} className="mb-3">
              <Form.Group id="mnumber">
                <Form.Control required type="number" placeholder="Mobile number" 
                                disabled={viewOnly}
                                value={profileDetails.mobileNumber} onChange={(event)=>{setProfileDetails({...profileDetails, mobileNumber:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            </Col>    
          </Row>
          <Row className="align-items-center">
            <Col md={4} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label><h5>Date Of Birth</h5></Form.Label>
                <Form.Control type="date" value={profileDetails.dateOfBirth}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, dateOfBirth:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="gender">
                <Form.Label><h5>Gender</h5></Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.gender} 
                                    disabled={viewOnly}
                                    onChange={(event)=>{setProfileDetails({...profileDetails, gender:event.target.value})}}
                                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group id="nationality">
                <Form.Label><h5>Nationality</h5></Form.Label>
                <Form.Control required type="text" placeholder="Nationality" value={profileDetails.nationality}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, nationality:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>

          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="maritalstatus">
                <Form.Label><h5>Place of Birth</h5></Form.Label> <br />
                <Form.Control type="text" size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.placeOfBirth}
                placeholder='Place of Birth...' disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, placeOfBirth:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="district">
                <Form.Label><h5>District</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.birthDistrict}
                    disabled={viewOnly} placeholder='District...'
                    onChange={(event)=>{setProfileDetails({...profileDetails, birthDistrict:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>

            <Col md={4} className="mb-3">
              <Form.Group id="state">
                <Form.Label><h5>State</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.birthState}
                  disabled={viewOnly} placeholder='State...'
                  onChange={(event)=>{setProfileDetails({...profileDetails, birthState:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="religion">
                <Form.Label><h5>Religious Status</h5></Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={profileDetails.religiousStatus} 
                  disabled={viewOnly}
                  onChange={(event)=>{setProfileDetails({...profileDetails, religiousStatus:event.target.value})}}
                >
                  <option value="Scholastic">Scholastic</option>
                  <option value="Brothers">Brothers</option>
                  <option value="Sisters">Sisters</option>
                  <option value="Laity">Laity</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label><h5>Parish & Diocese of Origin</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.parishDiocese}
                  disabled={viewOnly} placeholder='Parish & Diocese details...'
                  onChange={(event)=>{setProfileDetails({...profileDetails, parishDiocese:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
            {profileDetails.religiousStatus!=="Laity"&&
            <Col sm={4} className="mb-3">

              <Form.Group id="diocese">
                <Form.Label><h5>Congregation and Province</h5></Form.Label>
                <Form.Control required type="text" value={profileDetails.congregationProvince}
                  disabled={viewOnly} placeholder='Enter Congregation & Province details...'
                  onChange={(event)=>{setProfileDetails({...profileDetails, congregationProvince:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>}
          </Row>
          <Card><h5> Local Address</h5>
          <Row>
          <Col sm={12} className="mb-3">
              <Form.Group id="address">
                <Form.Label></Form.Label>
                <Form.Control required type="text" value={profileDetails.localAddress}
                    disabled={viewOnly} placeholder='Enter local address...'
                    onChange={(event)=>{setProfileDetails({...profileDetails, localAddress:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
              </Col>
          </Row>
          <Row>
          <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label><h5>State</h5></Form.Label>
                <Form.Control required type="text" placeholder="Enter your State" value={profileDetails.localState}
                    disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, localState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="localPincode">
                <Form.Label><h5>Pincode</h5></Form.Label>
                <Form.Control required type="text" placeholder="Postal Pincode" value={profileDetails.localPincode}
                    disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, localPincode:event.target.value})}}
                />
                
              </Form.Group>
          
               
            </Col>  
            <Col sm={4}>
              <Form.Group id="localContact">
                <Form.Label><h5>Contact No (Personal)</h5></Form.Label>
                <Form.Control required type="number" placeholder="Contact No" value={profileDetails.localContact}
                    disabled={viewOnly}
                    onChange={(event)=>{setProfileDetails({...profileDetails, localContact:event.target.value})}}
                />
              </Form.Group>
            </Col>            
          </Row>
          </Card>
          <Card> <h5>Permanent Address</h5>
          <Row>
          <Col sm={12} className="mb-3">
              <Form.Group id="address">
                <Form.Label></Form.Label>
                <Form.Control required type="text" value={profileDetails.perAddress}
                    disabled={viewOnly} placeholder='Enter permanent address....'
                    onChange={(event)=>{setProfileDetails({...profileDetails, perAddress:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
              </Col>
          </Row>
          <Row>
          <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label><h5>State</h5></Form.Label>
                <Form.Control required type="text" placeholder="Enter your State" value={profileDetails.perState}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perState:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label><h5>Pincode</h5></Form.Label>
                <Form.Control required type="text" placeholder="Postal Pincode" value={profileDetails.perPincode}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perPincode:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label><h5>Contact No (Gaurdian)</h5></Form.Label>
                <Form.Control required type="number" placeholder="Contact No" value={profileDetails.perContactNo}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setProfileDetails({...profileDetails, perContactNo:event.target.value})}}
                />
              </Form.Group>
            </Col>            
          </Row>
          </Card>
</Form>
</Card.Body>
        </Card>
            <InstitutionForm formType={"Edit"} form={profileDetails} setForm={setProfileDetails} />
            <LanguageForm formType={"Edit"} form={profileDetails} setForm={setProfileDetails}/>
            <Card className='p-2'>
              <h5 >ATTACHMENTS (Only Image Files are accepted)</h5>
            <Row>
                <Col className='pb-2' sm={6}>
                    <DocSuperiorLetter fileName="test" setRecommenLetterFile={setRecommenLetterFile}/>
                </Col>
                <Col className='pb-2' sm={6}>
                    <DocMarkList fileName="test" setMarkListFile={setMarkListFile}/>
                </Col>
            </Row>
            <Row>
                <Col className='pb-2' sm={6}>
                    <DocPhotoProof setImageFile={setImageFile}/>
                </Col>
                <Col className='pb-2' sm={6}>
                    <DocBPHCert fileName="test" setCertProofFile={setCertProofFile}/>
                </Col>
            </Row>
            <Row className='p-5 mb-2'>
            <Button className="btn bg-dark-main text-white py-3 px-3"  style={{textAlign:"center"}} type="submit" 
            onClick={event=>handlePreviewScreen(event)}>Preview</Button>
            </Row>
            </Card>
            </div>:null}

            
            {!isFormSubmitted && isPreviewScreen && <PreviewScreen optedCourse={optedCourse} profileDetails={profileDetails} recommenLetterFile={recommenLetterFile} 
            markListFile={markListFile} imageFile={imageFile} certProofFile={certProofFile} viewOnly={viewOnly} emailCodeEntered={emailCodeEntered} 
            setEmailCodeEntered={setEmailCodeEntered} handleSubmit={handleSubmit} handleEmailValidation={handleEmailValidation}/>}
  
        {applicationProcessed && isFormSubmitted ? <Card border="light" className=" container bg-white shadow-sm mb-4">
      <Card.Body>     
        <h5 className="mb-4">Registration - Final Status</h5>
        <Form>
        <Row>
        <h3 className="mb-4">Registration No - {app.appNo}</h3>
        </Row>
        <Row>
          <p>{app.appMsg}</p>
        </Row>
        <div className="mt-3 text-center">
            <Button className="btn bg-dark-main text-white" type="submit" onClick={(event)=>{handleNewForm(event)}}> New Form</Button>

          </div>

        </Form>
        </Card.Body>
     </Card>:null}
    </div>
  )
}
