import React from 'react';
import { AVEList } from '../../ProData';
import {Helmet} from "react-helmet-async";

export default function ACE() {
  return (
    <>
        <Helmet>
      <title>Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/publications" />
    </Helmet>

    <div className='container pb-5'>
      <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
          <h5 className="text-primary">AVE Reflection</h5>
          <h1>The students bring out a monthly publication ‘Ave’ in English which is meditations on the Lectionary readings of each day.</h1>
      </div>
      <div className="row g-5 justify-content-center">
        {AVEList.map((item, index)=>
            <div key={index} className="col-lg-6 col-xl-3 wow fadeIn" data-wow-delay=".3s">
                <div className="blog-item position-relative bg-light rounded">
                    <img src={item.poster} className="img-fluid w-100 rounded-top" alt="" style={{width:"80px", height:"300px"}} />
                </div>
            </div>)}
        </div>
    </div>
    </>
  )
}
