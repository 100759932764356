import React from 'react';
import formation1 from '../../assets/images/formation/formation2.jpg';
import formation2 from '../../assets/images/formation/formation1.jpg';
import formation3 from '../../assets/images/formation/formation3.jpg';
import ministry1 from '../../assets/images/ministry/p1.jpg';
import ministry2 from '../../assets/images/ministry/p2.jpg';
import ministry3 from '../../assets/images/ministry/p3.jpg';
import ministry4 from '../../assets/images/ministry/p4.jpg';
import ministry5 from '../../assets/images/ministry/p5.jpg';
import interreligiousimg from '../../assets/images/inter-religious-faith.jpg';

//import NewFaceView from '../NewFaceView';
//import AboutUsHeader from './AboutUsHeader';
import {Helmet} from "react-helmet-async";
import {Row, Col} from 'reactstrap';

export default function Formation() {
  return (
    <div className='sec'>
    <Helmet>
      <title>Formation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Formation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Formation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/about/formation" />
    </Helmet>

        {/*<AboutUsHeader />
        <NewFaceView />*/}
        <div className="sec vission-mission container-fluid">
        <h5 className="text-primary" style={{textAlign:"center"}}>About Us</h5>
            <h1 className="mb-4" style={{color:"#79031d", textAlign:"center"}}>Theological Formation</h1>
            <div className="container py-5">

            <h5 className="text-primary">THEOLOGICAL FORMATION OF JESUITS, SEMINARIANS AND MEN RELIGIOUS</h5>
            <div className="box">
            <img src={formation1} alt="The Forger family picture" className=" img-fluid rounded form-img"  style={{float: "left", margin: "5px"}}/>
            </div>
            <div>
            <p>  Pope Francis desires the priests to be shepherds with the smell of the sheep. Optatam Totius stresses, “pastoral concern… ought to permeate thoroughly the entire training of the students” (OT 19). Although the major part of the students in Vidyajyoti are Jesuit Scholastics from the South Asian Assistancy, a sizable number of students of Vidyajyoti come from Pratiksha, the diocesan seminary, and IMS Seminary. Besides, after two years of studies in the Regional Theology Centres (RTCs), now called Jesuit Formation Centres in Theology (JFCTs), students from various centres come to VJ for the third year of theology. Besides IMS, members of other religious congregations like MCBS, CST, MSFS, SG, SVD have been the students of Vidyajyoti. Although most of these students are for priesthood, some religious brothers also do theology courses.
                 </p>   
            </div>

            <h5 className="text-primary">THEOLOGICAL FORMATION OF WOMEN RELIGIOUS</h5>
            <div className="box">
            <img src={formation2} alt="The Forger family picture" className=" img-fluid rounded form-img"  style={{float: "right", margin: "5px"}}/>
            </div>
            <div>
            <p>  
            Although Vidyajyoti opened its doors to “all duly qualified students” including women in 1974, it was after three years in 1977 the first woman students entered its portals. Since then more than 300 women have studied theology in Vidyajyoti. A few women religious have also done their MTh and PhD. Since most of the students who completed theological formation here in VJ were involved in the formation ministry or in the leadership ministry of their respective congregations, the impact VJ made on the women students had a multiplier effect. VJ is one of the few faculties to appoint a woman as a full time faculty member. Vidyajyoti was fortunate to have Prof. Sr. Shalini Mulakkal PBVM, the first woman President of ITA, as a permanent teaching staff for about one and half decade. Presently Sr. Prabina Rudam IBVM is on the faculty of VJ and contributes to the theological and human formation of our students. 
            </p>   
            </div>

            <h5 className="text-primary">Theological Formation of Laity</h5>
            <div className="box">
            <img src={formation3} alt="The Forger family picture" className=" img-fluid rounded form-img"  style={{float: "left", margin: "5px"}}/>
            </div>
            <div>
            <p>  
            Vidyajyoti since its inception has been motivated by the contextual spirit of making theology to go beyond its traditional boundaries. Hence, it has kept educating our catholic laity in B.Th and Diploma format. Several years ago, the present Vice Rector of Pontifical Institute of Urbaniana, Prof. Gaetano Sabetta studied BTh in Vidayjyoti. Some names from the recent past are Ms. Pearl Drago, Ms. Grace David, and Mr. Vijayan. They were involved in all the ministries and social awareness programmes of the college, which has sharpened their socio-theological instinct and conscience. The presence of lay faithful in our regular theology classrooms have mutually benefitted our brothers and sisters, who come to do theology. Thus, it has paved way for integral growth. Not only that, lay persons also conduct theological courses as guest Professors. Already in 1972, a lady lecturer of Delhi University gave a course on Medieval Hinduism. 
            </p>   
            </div>

            <h5 className="text-primary">Theologizing with the Poor</h5>
            <div className="box">
            <img src={ministry1} alt="The Forger family picture" className=" img-fluid rounded form-img"  style={{float: "right", margin: "5px"}}/>
            </div>
            <div>
            <p>  
            Arrupe desired that Jesuits must “acquire an empathic knowledge of contemporary realities,” “to discern the problems and difficulties to search for the most satisfying solutions.” In this unique way, he visualized that Vidyajyoti would become “a centre of theological reflection on the problems of the contemporary world.” Theologizing with the poor within the broader contextualized approach to theology could “address itself to the problems of poverty, or rather it would become good news for the poor themselves.” For George Gispert Sauch, SJ, an Indologist, “the transfer to Delhi was in the hope that this contact would enable a new method of theologizing to surface. A new theologizing process with the experience of reality, reflection, and osmosis between the biblical expression of revelation as lived in the Church and today’s revelation as manifested in the life and sufferings of the poor.” 
            </p>   
            </div> 

            <div className="box">
            <img src={ministry2} alt="The Forger family picture" className=" img-fluid rounded form-img"  style={{float: "left", margin: "5px"}}/>
            </div>
            <div>
            <p>  
            Vidyajyoti in its past fifty years has made a sincere effort to be part of the life and struggles of the poor in the city of Delhi through its involvement in various slums, jhuggi-jhompri settlements, centres for TB and leprosy patients run by the Missionaries of Charity, Rikshawpullers, Street children, Sex workers, Adivasi domestic workers, prisoners in Tihar Jail, and Refugees. In these slums, and other centres students mainly focus on tuition, motivational programs, and conscientization.   Asha ki Kiran, a ministry among the Rickshaw pullers, promotes a sense of dignity, and self-respect and promotes a habit of saving and keeping themselves clean and hygienic. For many Rikshawpullers Vidyajyoti is a ‘home’ where they are provided with food, clothing, and medicine. 
            </p>   
            </div>

            <div className="box">
            <img src={ministry3} alt="The Forger family picture" className=" img-fluid rounded form-img"  style={{float: "right", margin: "5px"}}/>
            </div>
            <div>
            <p>  
            Fieldwork is an important component of theological methodology and a requirement for a B.Th. degree that forges an alliance with the poor. It is not just rendering service to those in the margins but has theological and spiritual significance as it contributes to the pedagogy of contextual theology. Vidyajyoti has constantly promoted subaltern theologies - Dalit, Adivasi, and Feminist. Paulose Mangai, says that “they are formative experiences which call for theological and spiritual integration. In the vision, curriculum, and practice of Vidyajyoti, theology is learned during the lectures, in the library, through group reflections and personal prayers, and from the field experiences.” Fieldwork enables students to identify themselves with the “joy and hopes, the grief and anguish” of the poor of our times. 
            </p>   
            </div> 
                    
            <div className="box">
            <img src={ministry4} alt="The Forger family picture" className=" img-fluid rounded form-img"  style={{float: "left", margin: "5px"}}/>
            </div>
            <div>
            <p>  
            In accompanying the poor, there have been special and decisive moments. To name a few of them where relief and support have been provided; 1977 cyclone, 1984 Anti-Sikhs riots, 1991 Uttarkashi earthquake; 1999 Orissa cyclone; 2005 Kashmir Earthquake, 2008 Kosi river flood, 2015 Nepal Earthquake and 2020 riots in Northeast Delhi. Vidyajyoti accompanied the people of Sultanpuri resettlement colony and the Sanjay Amar JJ colony during their displacement. The students participated in the Karwan-e-Mohabbat (Caravan of Love) or a peace Yātra, advocated by human rights activist Harsh Mander in 2017 in order to respond to the growing globalization of hate among religions. Along with these involvements, students and staff of Vidyajyoti regularly participate to protest at Jantar Mantar to claim the rights of marginalized sections.  
            </p>   
            </div>

            <div className="box">
            <img src={ministry5} alt="The Forger family picture" className=" img-fluid rounded form-img"  style={{float: "right", margin: "5px"}}/>
            </div>
            <div>
            <p>  
            Delhi has multiple openings with the intellectual academic world, the world of the poor, and the world of social activists, and civil society organizations. The experiences of Vidyajyoti have empowered many students to be at the forefront of the action in different parts of the country working on issues affecting the lives of the poor. Vidyajyoti through its involvement with the poor forms students not to “look away” from the situation of inequality, prejudice, and indifference in India. Aloysius Pieris SJ in the context of poverty and religiosity of Asia at a theological response on the level of praxis calls for a “double baptism” with “multiple poor and multiple religions.” Vidyajyoti in its dialogue with the religions and poor fosters such a double baptism.
            </p>   
            </div> 

            <h5 className="text-primary">IMMERSION INTO OTHER FAITH DIMENSIONS</h5>
            <div className="box">
            <img src={interreligiousimg} alt="The Forger family picture" className=" img-fluid rounded form-img"  style={{float: "left", margin: "5px"}}/>
            </div>
            <div>
            <p>  
            The students of Vidyajyoti look at other religions not as a kind of neutral object, to satisfy their intellectual curiosity. But sincerely appreciate them for their cultural, textual, and ritual world, with all admiration and respect. They discover ways to engage other religions, especially for those teachings that recognize and nurture the dignity of the human person. The regular immersion programs at Varanasi, Haridwar, and Rishikesh helps them to participate in this transformatory processes. Theological insights on inculturation relying on deep dialogue shared by Abhishiktananda, and Vandana Mataji have enriched the students of Vidyajyoti.
            </p>   
            <p>                    
                Immersion into other religions is a difficult journey. Initially, the language, rituals, religious traditions, symbols, myths, and fundamental attitudes of moral and religious life may seem foreign. However, with a systematic introduction to the sacred texts and engagement with practitioners of the religions, they learn to enter with awe and epistemological humility into the very shrine of diverse religious faiths. The learning becomes easier when one begins to recognise the working of the Spirit of God and the hidden presence of Christ in peoples, their histories, and their religions. The immersion of Vidyajyoti students within the multi-religious realities of our nation has formed them to carry forward ‘Missio Dei,’ and approach the religious other in a ‘dialogue of life.’ Such encounters have facilitated the process of recognition, in the words of Jacques Dupuis, “the world’s religious traditions represent not the search of people for God but God’s search for them.” 
            </p>
            </div>

            </div>
        </div>
    </div>
  )
}
