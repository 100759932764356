import React, { useEffect, useState } from 'react';
import './UpcomingModal.css';
import logo from '../../../../assets/logo/VJ-College-logo.png';

const config = require('../../../../config.json');

export default function UpComingView(props) {
  const {eventDetails, handleClose, index, setIndex}=props;
  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    //console.log("event details:", eventDetails);
    setLoaded(true);
  },[])

  const handleNext=()=>{
    const tindex=index;
    if(tindex+1>eventDetails.length-1){
      setIndex(0);
    }else{
      setIndex(index+1);
    }
  }

  const handlePrevious=()=>{
    const tindex=index;
    if(tindex-1<0){
      setIndex(eventDetails.length-1);
    }else{
      setIndex(index-1);
    }
  }


  return (
    <div>
                {loaded && <div className="" role="document">
                  <div className="modal-content">
                    <div className="modal-body row">
                      <div className="content col-sm-6">
                        <h3 className="title red-title">{eventDetails[index].topStory.timelineHeader}</h3>
                        <span className="sub-title">{eventDetails[index].topStory.timelineTitle}</span>
                        <p className="description">{eventDetails[index].topStory.timelineDescription}</p>
                      </div>
                      <div className="col-sm-6 modal-image">
                      {eventDetails[index].topStory.timelineImage ? <div className="title"><img src={`${config.s3.ImageinvokeURL}${"images/upcomingevents/"}${eventDetails[index].topStory.timelineImage}`} alt={eventDetails[index].topStory.timelineTitle} width="340px" height="340px"/></div>:
                       <div className="title"><img src={logo} alt={eventDetails[index].topStory.timelineTitle} width="340px" height="340px"/></div>}
                      </div>

                    </div>
                    <div className="flex text-center justify-between space-x-4 p-2">
                    <button className="btn text-center center" onClick={handlePrevious}>{"<--"}</button>
                    <button className="btn text-center center" onClick={handleClose}>Close</button>
                    <button className="btn text-center center" onClick={handleNext}>{"-->"}</button>
                    </div>

                  </div>
                </div> }

    </div>
  )
}
